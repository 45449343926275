<template lang="pug">
b-container#app.p-0(fluid)(
  :class="{ 'is-medten-customer': isMedtenCustomer }",
  v-if="customer"
)
  Header
  b-overlay(:show="has_global_error")
    template(#overlay)
      .text-center.text-danger
        b-icon-exclamation-triangle
        p {{ global_error }}
    router-view
</template>

<script>
import { mapState, mapActions } from 'pinia';
import Header from '@/components/Header.vue';
import { useDbStore } from '@/stores/db-st';
import { useBaseStore } from '@/stores/base-st';
export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {};
  },

  computed: {
    ...mapState(useBaseStore, [
      'message',
      'error',
      'errors',
      'busy',
      'global_error',
      'has_error',
      'has_global_error',
      'has_message',
    ]),
    ...mapState(useDbStore, ['customer', 'isMedtenCustomer']),
  },

  async created() {
    // "silent" login
    this.loadCustomerInfo();
    this.loadLifecycles();
    this.loadDocumentTypes();
    this.setFavicons();
    this.setTitle();
    if (!this.$router.currentRoute || !this.$router.currentRoute.name)
      this.$router.push({ name: 'Landing' });
  },

  watch: {},

  methods: {
    ...mapActions(useDbStore, [
      'hydrateCustomer',
      'loadLifecycles',
      'loadDocumentTypes',
    ]),
    setCookie(name, value, days) {
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/';
    },
    eraseCookie(name) {
      document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    setupGoogleTranslation() {
      if (this.customer.language) {
        this.setCookie('googtrans', this.customer.language, 365);
      } else {
        this.eraseCookie('googtrans');
      }
      const timer = setInterval(() => {
        if (google && google.translate && google.translate.TranslateElement) {
          clearInterval(timer);
          function googleTranslateElementInit() {
            new google.translate.TranslateElement(
              { pageLanguage: 'en' },
              'google_translate_element'
            );
          }
          googleTranslateElementInit();
        }
      }, 1000);
    },
    async loadCustomerInfo() {
      await this.hydrateCustomer();
      this.setupGoogleTranslation();
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    setTitle() {
      const medtenSites = [1, 2, 3];
      if (!medtenSites.includes(this.customer?.id)) {
        document.title = 'Product Catalog';
      }
    },
    setFavicons() {
      if (!this.isMedtenCustomer) {
        const faviconEle = document.querySelector('#favicon');
        faviconEle.href = './favicon-brands.ico';
        return;
      }
      if (window.location.hostname.indexOf('test.') > -1) {
        const faviconEle = document.querySelector('#favicon');
        faviconEle.href = './favicon-test.ico';
      }
    },
  },
};
</script>
<style>
.custom-arrow {
  z-index: 1 !important;
  width: auto !important;
  height: auto !important;
  top: 45% !important;
}
#chat-widget-container {
  display: none !important;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: #38bcd8;
  border-radius: 50%;
  border: 1px solid #38bcd8;
  display: inline-block;
  margin-right: 4px;
}
.custom-arrow.slick-disabled {
  opacity: 0.5;
}
.desktop-show {
  display: none !important;
}
.mobile-show {
  display: flex;
}
mark,
.mark {
  padding: 0px;
  background-color: #faf11d;
}
@media (min-width: 768px) {
  .desktop-show {
    display: block !important;
  }
  .mobile-show {
    display: none !important;
  }
}
/* #app:not(.is-medten-customer) ~ #chat-widget-container {
  display: none;
} */
#landing .carousel-image img {
  width: 1200px !important;
  margin: auto;
}
#google_translate_element .skiptranslate {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
}
.banner-carousel .carousel-control-prev-icon,
.banner-carousel .carousel-control-next-icon {
  opacity: 0;
}

.banner-carousel:hover .carousel-control-prev-icon,
.banner-carousel:hover .carousel-control-next-icon {
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  width: 25px;
  height: 30px;
}
/* 


body {
  top: 0px !important;
  position: inherit !important;
} */
</style>
